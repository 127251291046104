/* Imports */
@import url(../css/partials/_global.css);
@import url(../css/partials/_neon_effect.css);

/* Fonts */
@font-face {
  font-family: 'Cabin';
  src: url(../fonts/Cabin/Cabin-Regular.ttf);
}

/* Default Settings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cabin', sans-serif;
}

/* Preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fffffffc;
  z-index: 99999999;
  height: 100%;
}

.preloader .preloader_image {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* eof Preloader */

/* Header */
header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 50px;
  z-index: 9000;
  transition: 0.3s;
}

header ul,
#projects .projects_container .projects_sort ul {
  position: relative;
  display: flex;
}

header ul li,
#projects .projects_container .projects_sort ul li {
  position: relative;
  list-style: none;
  margin-left: 36px;
}

header ul li.active a,
header ul li a:hover,
header ul li a:focus,
#projects .projects_container .projects_sort ul li.active a,
#projects .projects_container .projects_sort ul li a:hover,
#projects .projects_container .projects_sort ul li a:focus {
  background-size: 100% 2px;
}

header ul li a,
#projects .projects_container .projects_sort ul li a {
  position: relative;
  display: inline-block;
  color: #fff;
  font-size: 13px;
  padding-bottom: 5px;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
}

header.sticky {
  background: #fff;
  padding: 20px 50px;
  box-shadow: 0 5px 20px #0000001a;
}

header.sticky ul li a,
#projects .projects_container .projects_sort ul li a {
  color: #111;
}

header.sticky ul li.active a,
header.sticky ul li a:hover,
header.sticky ul li a:focus,
#projects .projects_container .projects_sort ul li.active a,
#projects .projects_container .projects_sort ul li a:hover,
#projects .projects_container .projects_sort ul li a:focus {
  color: #e0312f;
}

/* Logo */
header .logo .logo_img {
  width: 50px;
  height: 50px;
  background: url(../img/logo1.svg) no-repeat;
  background-size: contain;
  transition: 0.3s;
}

header.sticky .logo .logo_img {
  background: url(../img/logo2.svg) no-repeat;
  background-size: contain;
}
/* eof Header */

/* Intro */
.intro_area .intro_content {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 100px;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}

.intro_area .intro_content .text_box {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #00000077;
  z-index: 100;
}

.intro_area h2 {
  text-align: center;
  font-weight: 500;
  font-size: 3em;
  line-height: 1.2em;
  color: #fff;
}

.intro_area h2 span {
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.05em;
}

.intro_area .site_buttons {
  display: flex;
  align-items: center;
}

.intro_area .site_buttons a {
  margin: 20px auto;
}

/* eof Intro */

/* About */
#about,
#services,
#projects,
#testimonials,
#blog,
#contacts,
footer {
  padding: 40px 20px;
}

.heading {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  color: #111;
}

.heading h2 {
  font-weight: 500;
  font-size: 40px;
}

#about .content.about_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#about .content.about_content .about_content_box {
  padding: 10px;
}

#about .content.about_content .about_content_box h3 {
  font-size: 30px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}

#about .content.about_content .about_item {
  max-width: 600px;
  margin: 0 auto;
}

#about .social_media_icons {
  width: 100%;
  justify-content: left;
  padding: 10px 0;
}

#about .social_media_icons p {
  color: #818181;
  padding: 10px 0;
}

#about .social_media_icons a[title='Github']:hover {
  color: #111;
}

#about .social_media_icons a[title='Dev']:hover {
  color: #191970;
}

#about .social_media_icons a[title='LinkedIn']:hover {
  color: #0e76a8;
}

#about .content.about_content .about_pic {
  padding: 10px;
}

#about .content.about_content .about_pic img {
  max-width: 100%;
  border-radius: 5px;
}
/* eof About */

/* Services */
#services {
  background: #111;
}

.heading.white_letters,
.white_letters {
  color: #fff;
}

#services .services_container {
  display: flex;
  justify-content: center;
}

#services .content.services_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1500px;
}

#services .content.services_content .services_content_box {
  padding: 40px 20px;
  background: #222;
  border-radius: 5px;
  color: #fff;
  margin: 20px;
  max-width: 340px;
  text-align: center;
  transition: 0.5s;
}

#services .content.services_content .services_content_box:hover {
  background: #e0312f;
}

#services .content.services_content .services_content_box img {
  max-width: 80px;
  -webkit-filter: invert(1);
  filter: invert(1);
}

#services .content.services_content .services_content_box h2 {
  font-size: 20px;
  font-weight: 500;
}
/* eof Services */

/* Projects */
#projects .projects_container .projects_wrapper {
  width: 1530px;
  margin: 0 auto;
}

#projects .projects_container .projects_sort ul {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#projects .projects_container .projects_sort ul li {
  margin-bottom: 10px;
}

#projects .content.projects_content .filtr-item {
  max-width: 500px;
  overflow: hidden;
  max-height: 500px;
  padding-top: 30px;
  padding-left: 30px;
}

#projects .content.projects_content .filtr-item img {
  max-width: 100%;
  border-radius: 5px;
}

#projects .projects_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#projects .projects_container .projects_sort ul li {
  margin-left: 18px;
  margin-right: 18px;
}

#projects .overlay_contents {
  background: #00000099;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  position: absolute;
  width: calc(100% - 30px);
  bottom: 0;
  transition: transform 0.3s;
  transform: translateY(50%);
  padding: 20px;
}

#projects .overlay_contents h3 {
  font-size: 1.5em;
}

#projects .overlay_contents h3,
#projects .overlay_contents p {
  color: #fff;
  text-align: center;
}

#projects .overlay_contents .slide_up_overlay {
  display: flex;
  justify-content: center;
}

#projects .overlay_contents .slide_up_overlay a {
  margin: 40px 20px 20px 20px;
}

#projects
  .content.projects_content
  .filtr-item
  .projects_img:hover
  .overlay_contents,
#projects
  .content.projects_content
  .filtr-item
  .projects_img:focus
  .overlay_contents,
#projects
  .content.projects_content
  .filtr-item
  .projects_img:hover
  .overlay_contents
  h3,
#projects
  .content.projects_content
  .filtr-item
  .projects_img:focus
  .overlay_contents
  h3 {
  transform: translateY(0);
}

/* eof Projects */

/* Testimonials */
#testimonials {
  background: url(../img/parallax.jpg);
  background-size: cover;
  background-attachment: fixed;
}

#testimonials .testimonials_content {
  max-width: 800px;
  margin: 0 auto;
}

#testimonials .testimonials_content .testimonials_content_item {
  margin-right: 10px;
  width: 100%;
}

#testimonials .testimonials_content .testimonials_content_item .snippet {
  background-color: #ffffffed;
  border-radius: 5px;
  min-height: 160px;
  display: flex;
  align-items: center;
}

#testimonials .testimonials_content .testimonials_content_item .snippet p {
  font-size: 20px;
  text-align: center;
  padding: 0 10px;
  letter-spacing: 1px;
  line-height: 1.3;
}

#testimonials
  .testimonials_content
  .testimonials_content_item
  .snippet
  p
  .quote-marks {
  font-size: 70px;
  font-style: italic;
}

#testimonials .testimonials_content .testimonials_content_item .client {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  margin: 30px auto;
}

#testimonials .testimonials_content .testimonials_content_item .client img {
  margin: 0 auto;
  border-radius: 130px;
  height: 130px;
  width: 130px;
}

#testimonials .testimonials_content .testimonials_content_item .client p {
  color: #fff;
  text-align: center;
}

.flickity-prev-next-button {
  display: none;
}

.flickity-page-dots .dot {
  width: 15px;
  height: 15px;
  opacity: 1;
  background: transparent;
  border: 2px solid #e0312f;
}

.flickity-page-dots .dot.is-selected {
  background: #e0312f;
}

/* eof testimonials */

/* Blog */
#blog .blog_content {
  max-width: 1300px;
  margin: 0 auto;
}

#blog .blog_content .blog_content_box {
  max-width: 420px;
  padding: 20px;
  margin: 10px auto;
}

#blog .blog_content .blog_content_box article {
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

#blog .blog_content .blog_content_box article:hover .blog_content_pic img {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}

#blog .blog_content .blog_content_box article .blog_content_pic img {
  max-width: 100%;
  transition: 0.3s;
}

#blog .blog_content .blog_content_box article .blog_content_excerpt {
  min-height: 200px;
  padding: 15px;
  text-align: center;
}

#blog .blog_content .blog_content_box article .topic_date {
  display: flex;
  justify-content: space-between;
}

#blog
  .blog_content
  .blog_content_box
  article
  .blog_content_excerpt
  .topic_date
  h5 {
  color: #e0312f;
  font-size: 12px;
}

#blog
  .blog_content
  .blog_content_box
  article
  .blog_content_excerpt
  .topic_date
  p {
  color: #818181;
  font-size: 12px;
}

#blog
  .blog_content
  .blog_content_box
  article
  .blog_content_excerpt
  .main_content {
  margin: 10px;
}

#blog
  .blog_content
  .blog_content_box
  article
  .blog_content_excerpt
  .main_content
  h3 {
  padding: 5px 0;
}

#blog
  .blog_content
  .blog_content_box
  article
  .blog_content_excerpt
  .main_content
  p {
  color: #818181;
  text-align: justify;
}

#blog .blog_content .blog_content_box article .blog_content_data {
  color: #818181;
  border-top: 1px solid #e6e6e6;
  border-bottom: 3px solid #e0312f;
  padding: 20px 0;
}

#blog .blog_content .blog_content_box article .blog_content_data div {
  margin: 0 auto;
  width: 45px;
}

/* eof Blog */

/* Contacts */
#contacts {
  background-color: #111;
}

#contacts .contacts_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  padding: 0 20px;
}

#contacts .contacts_content .contacts_details_box {
  color: #fff;
}

#contacts .contacts_content .contacts_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#contacts .contacts_content .contacts_details,
#contacts .contacts_content .contacts_form {
  max-width: 500px;
  width: 100%;
}

#contacts .contacts_content .contacts_details_box .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

#contacts .contacts_content .contacts_details_box .icon,
#contacts .contacts_content .contacts_form h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

#contacts .contacts_content .contacts_details_box .icon i {
  color: #e0312f;
  margin-right: 10px;
}

#contacts .contacts_content .contacts_form .input_area {
  margin-bottom: 20px;
  padding: 15px;
  font-size: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  resize: none;
}

#contacts .contacts_content .contacts_form textarea.input_area {
  min-height: 150px;
}

#contacts .contacts_content .contacts_form input[type='submit'] {
  margin: 0 auto;
}

#contacts .contacts_content .contacts_details_box a.highlight_on_hover,
a.highlight_on_hover {
  text-decoration: none;
  color: #fff;
  transition: 0.3s;
}

#contacts .contacts_content .contacts_details_box a.highlight_on_hover:hover,
a.highlight_on_hover:hover {
  color: #e0312f;
}

#contacts .contacts_content .contacts_details_box {
  margin-bottom: 15px;
}

#contacts .contacts_content .contacts_form form {
  display: flex;
  flex-direction: column;
}

/* eof Contacts */

/* Footer */
footer {
  background-color: #111;
}

.social_media_icons {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.social_media_icons a {
  color: #b1b1b1;
  text-decoration: none;
  font-size: 20px;
  margin: 10px 15px;
  transition: 0.3s;
}

.social_media_icons a:hover {
  -webkit-filter: brightness(1.5);
  filter: brightness(1.5);
}

.social_media_icons a[title='Twitter']:hover {
  color: #00acee;
}

.social_media_icons a[title='Dev']:hover {
  color: #fff;
}

.social_media_icons a[title='Github']:hover {
  color: #fff;
}

.social_media_icons a[title='LinkedIn']:hover {
  color: #fff;
}

.social_media_icons a[title='Youtube']:hover {
  color: #ff0000;
}
/* eof Footer */

/* Copyright */
#copyright {
  background-color: #fff;
}

.social_media_icons div {
  color: #b1b1b1;
  font-size: 13px;
  margin: 30px;
  text-align: center;
}
/* eof Copyright */

/* Floating Action Button */
#fab {
  bottom: 60px;
  right: 60px;
  position: fixed;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-weight: 600;
  text-align: center;
  z-index: 900;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  transition: all 0.2s ease-in-out 0s;
}

#fab:hover {
  bottom: 65px;
  color: #fff;
}
/* eof ScrollTop */

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffffec;
}

::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background: #444343;
}
/* eof Scrollbar */

/* Responsive */
@media (max-width: 1650px) {
  .intro_area .intro_content {
    padding: 150px 100px;
  }
}

@media (max-width: 1590px) {
  #about .content.about_content .about_item {
    max-width: 550px;
  }

  #projects .projects_container .projects_wrapper {
    width: 1030px;
  }
}

@media (max-width: 1300px) {
  #blog .blog_content {
    max-width: 900px;
  }
}

@media (max-width: 1140px) {
  #about .content.about_content .about_item {
    max-width: 450px;
  }

  #projects .projects_container .projects_wrapper {
    max-width: 530px;
  }
}

@media (max-width: 1100px) {
  header,
  header.sticky {
    padding: 15px 50px;
  }

  header.active_menu {
    background: #000;
  }

  header.sticky.active_menu {
    background: #fff;
  }

  header ul.main_menu {
    position: fixed;
    top: 80px;
    left: -100%;
    display: block;
    padding: 100px 50px;
    text-align: center;
    width: 100%;
    height: 100vh;
    background: #fff;
    transition: 0.4s;
    z-index: 8999;
  }

  header ul.main_menu.active_menu {
    left: 0;
  }

  header ul.main_menu li {
    margin: 30px;
  }

  header ul.main_menu li a {
    font-size: 17px;
    color: #111;
  }

  header ul.main_menu li.active a,
  header ul.main_menu li a:hover,
  header ul.main_menu li a:focus {
    color: #e0312f;
  }

  .toggle {
    width: 50px;
    height: 50px;
    background: url(../img/hamburger_menu_icon.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    transition: 0.3s;
  }

  .toggle.active_menu {
    background: url(../img/close-icon.svg) no-repeat;
    background-size: contain;
  }

  header.sticky .toggle {
    -webkit-filter: invert(1);
    filter: invert(1);
  }

  #testimonials {
    background-attachment: local;
  }
}

@media (max-width: 950px) {
  header,
  header.sticky {
    padding: 15px 35px;
  }

  .primary_button,
  .secondary_button {
    margin: 25px auto;
  }

  .intro_area h2 {
    font-size: 1.5em;
  }

  .heading h2 {
    font-size: 30px;
  }

  .intro_area .site_buttons {
    flex-direction: column;
  }

  #about .content.about_content .about_item {
    max-width: 600px;
  }

  .social_media_icons div {
    font-size: 8px;
  }
}

@media (max-width: 550px) {
  .preloader .preloader_image {
    height: 50px;
    width: 50px;
  }

  header,
  header.sticky {
    padding: 11px 19px;
  }

  p {
    font-size: 13px;
  }

  header ul.main_menu {
    top: 72px;
  }

  .primary_button,
  .secondary_button {
    font-size: 9px;
    padding: 18px 35px;
    margin: 20px auto;
  }

  #about .social_media_icons p {
    font-size: 12px;
  }

  #testimonials .testimonials_content .testimonials_content_item .snippet p,
  .social_media_icons a {
    font-size: 15px;
  }

  #projects .projects_container .projects_wrapper {
    max-width: 430px;
  }

  #projects .content.projects_content .filtr-item {
    max-width: 400px;
    max-height: 400px;
  }

  #fab {
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  #fab:hover {
    bottom: 35px;
  }
}

@media (max-width: 480px) {
  #projects .projects_container .projects_wrapper {
    max-width: 340px;
  }

  #projects .content.projects_content .filtr-item {
    max-width: 340px;
    max-height: 340px;
    padding-left: 0;
  }

  #projects .overlay_contents {
    width: 100%;
  }
}

@media (max-width: 380px) {
  #projects .projects_container .projects_wrapper {
    max-width: 300px;
  }

  #projects .content.projects_content .filtr-item {
    max-width: 300px;
    max-height: 300px;
  }
}
